import React, {useState} from "react";
import Input from './Input';
import Select from './Select';
import Textarea from './Textarea';
import Button from './Button';
import {Link} from 'gatsby';
import {useForm} from 'react-hook-form';
import clsx from "clsx";
import { useHsSubmit } from "../hooks/use-hs-submit";

const companyTypes = [
  { value: 'Pre-Revenue', label: 'Pre-Revenue ($0 Turnover)' },
  { value: 'Startup', label: 'Start Up ($20k - 200k Revenue)' },
  { value: 'Scale Up', label: 'Scale-Up (<$1m Turnover)' },
  { value: 'Established', label: 'Established (<$5m Turnover)' },
  { value: 'Enterprise', label: 'Enterprise ($5m+ Turnover)' },
];
const locations = [
  { value: 'Australia - Victoria', label: 'Australia - Victoria' },
  { value: 'Australia - New South Wales', label: 'Australia - New South Wales' },
  { value: 'Australia - Queensland', label: 'Australia - Queensland' },
  { value: 'Australia - South Australia', label: 'Australia - South Australia' },
  { value: 'Australia - Western Australia', label: 'Australia - Western Australia' },
  { value: 'Australia - Northern Territory', label: 'Australia - Northern Territory' },
  { value: 'Australia - Tasmania', label: 'Australia - Tasmania' },
  { value: 'Australia - ACT', label: 'Australia - ACT' },
  { value: 'USA', label: 'USA' },
  { value: 'New Zealand', label: 'New Zealand' },
  { value: 'Other', label: 'Other' },
];
const investOptions = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No'}
];
const hearAboutUs = [
  { value: 'Google Search', label: 'Google Search' },
  { value: 'Facebook', label: 'Facebook Ad' },
  { value: 'Instagram', label: 'Social Media' },
  { value: 'Word of Mouth', label: 'Word of Mouth' },
];

const ErrorField = ({msg}) => {
  return (
    <span className="text-red-500 text-sm">{msg}</span>
  )
}

const ContactForm = ({
  bg = 'dark',
  hsFormPortalId = '7854587',
  hsFormId = '2d395d2b-ca3d-461e-b98e-65c8cd7aeeb3',
  location = 'Website Contact Form'
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showReadyToInvest, setShowReadyToInvest] = useState(false);
  const {register, unregister, handleSubmit, formState: {errors}} = useForm();
  const [errorMessage, submit] = useHsSubmit(hsFormPortalId, hsFormId, location);
  const onSubmit = async data => {
    // Legacy field.
    if(data.are_you_ready_to_invest_2000030000_in_your_products === undefined) {
      data.are_you_ready_to_invest_2000030000_in_your_products = 'N/A'
    }
    return await submit(data)
  };
  const handleChange = (name, selectedOption) => {
    if(name === 'company_type') {
      if((selectedOption.value === 'Pre-Revenue' || selectedOption.value === 'Startup')) {
        setShowReadyToInvest(true);
      }
      else {
        setShowReadyToInvest(false);
        unregister('are_you_ready_to_invest_2000030000_in_your_products');
      }
    }
  }

  const textColor = bg === 'dark' ? 'text-white' : 'text-black';
  const fieldColorStyle = bg === 'dark' ? '' : 'shadow focus-visible:outline-slate-200';
  const fieldStyle = clsx('w-full', fieldColorStyle);
  return (
    <form className="grid lg:grid-cols-2 gap-4"
      onSubmit={handleSubmit(async (data) => {
        setIsSubmitting(true);
        await onSubmit(data)
        .then(data => {
          setIsSubmitting(false);
        })
        .catch(e => {
          setIsSubmitting(false);
        })
      })}>
      <div>
        <Input placeholder="First name"
          className={fieldStyle}
          register={register}
          required={true}
          name="firstname"
          error={errors.firstname} />
        {errors.firstname && <ErrorField msg="Please enter your first name" />}
      </div>
      <div>
        <Input placeholder="Last name"
          className={fieldStyle}
          register={register}
          required={true}
          error={errors.lastname}
          name="lastname" />
        {errors.lastname && <ErrorField msg="Please enter your last name" />}
      </div>
      <div className="lg:col-span-2">
        <Input placeholder="Company Name"
          className={fieldStyle}
          register={register}
          error={errors.company}
          name="company" />
        {errors.company && <ErrorField msg="Please enter your company name" />}
      </div>
      <div>
        <Input placeholder="Phone number"
          className={fieldStyle}
          register={register}
          required={{
            value: true,
            message: 'Please enter your phone number',
          }}
          pattern={{
            value: /\d+/,
            message: 'Please enter a valid phone number',
          }}
          name="phone"
          error={errors.phone} />
        {errors.phone && <ErrorField msg={errors.phone.message} />}
      </div>
      <div>
        <Select options={companyTypes}
          className={clsx('h-11', fieldStyle)}
          placeholder="What best describes you?"
          register={register}
          validate={{
            contains: v => companyTypes.map((i,k) => i.value).includes(v)
          }}
          required={true}
          error={errors.company_type}
          onChange={handleChange}
          name="company_type" />
        {errors.company_type && <ErrorField msg="Please select an option" />}
      </div>
      <div className="lg:col-span-2">
        <Input placeholder="Email address"
          type="email"
          className={fieldStyle}
          register={register}
          required={{
            value: true,
            message: 'Please enter your email address',
          }}
          pattern={{
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: 'Please enter a valid email address',
          }}
          error={errors.email}
          name="email" />
        {errors.email && <ErrorField msg="Please enter your email address" />}
      </div>
      <div className="lg:col-span-2">
        <Select options={locations}
          className={clsx('h-11', fieldStyle)}
          placeholder="Where are you based?"
          register={register}
          required={true}
          validate={{
            contains: v => locations.map((i,k) => i.value).includes(v)
          }}
          error={errors.where_are_you_based_}
          name="where_are_you_based_" />
        {errors.where_are_you_based_ && <ErrorField msg="Please select an option" />}
      </div>
      {showReadyToInvest && <div className="lg:col-span-2">
        <Select options={investOptions}
          className={clsx('h-11', fieldStyle)}
          placeholder="Are you ready to invest $40,000+ into inventory?"
          register={register}
          required={true}
          validate={{
            contains: v => investOptions.map((i,k) => i.value).includes(v)
          }}
          error={errors.are_you_ready_to_invest_2000030000_in_your_products}
          name="are_you_ready_to_invest_2000030000_in_your_products" />
        {errors.are_you_ready_to_invest_2000030000_in_your_products && <ErrorField msg="Please select an option" />}
      </div>}
      <div className="lg:col-span-2">
        <Textarea placeholder="Tell us about your product or project"
          rows="4"
          className={fieldStyle}
          register={register}
          name="your_message" />
      </div>
      {/* <div className="lg:col-span-2">
        <Select options={hearAboutUs}
          className={clsx('h-11', fieldStyle)}
          placeholder="So, how did you find out about Sourci?"
          register={register}
          required={true}
          validate={{
            contains: v => hearAboutUs.map((i,k) => i.value).includes(v)
          }}
          error={errors.user_source}
          name="user_source" />
        {errors.user_source && <ErrorField msg="Please select an option" />}
      </div> */}
      <div className="lg:col-span-2">
        <span className={clsx('text-sm', textColor)}>By submitting this form, you confirm that you've read and accepted our <Link to="/privacy-policy" className="underline">Privacy Policy</Link></span>
      </div>
      {errorMessage &&
        <div className="lg:col-span-2">
          <ErrorField msg={errorMessage} />
        </div>
      }
      <div>
        <Button label="Send"
          type="submit"
          className={clsx('bg-sourci-teal hover:bg-sourci-teal-100 transition-colors font-black text-white text-lg md:text-xl', isSubmitting && 'opacity-30')}
          padding="px-20 py-1.5" />
      </div>
    </form>
  )
}

export default ContactForm;
